export default ({
                    url: streamUrl,
                    contain,
}) => ({
    streamUrl,
    contain,
    startedInitialisingPlayer: false,
    playerInstance: null,
    playerIsReady: false,
    playerIsLoading: true,
    playerIsVisibleOnScreen: false,
    hls: null,

    init() {
        if (this.projectGalleryIsOpen !== undefined) {
            // if no slide id is provided, eg. we're not in a slider,
            // but a projectGalleryIsOpen is defined, this means that it's
            // a thumbnail (in the project detail gallery).
            // When the fullscreen gallery is opened, stop the video.
            this.$watch('projectGalleryIsOpen', (projectGalleryIsOpen) => {
                this.playOrPauseVideo(!projectGalleryIsOpen);
            });
        }
    },

    playOrPauseVideo(shouldPlay) {
        if(shouldPlay) {
            this.videoEntersViewport();
        } else {
            this.videoLeavesViewport();
        }
    },

    videoEntersViewport() {
        this.playerIsVisibleOnScreen = true;
        this.startPlayback();
    },

    videoLeavesViewport() {
        this.playerIsVisibleOnScreen = false;
        this.pausePlayback();
    },

    onFocus() {
        this.startPlayback();
    },

    onBlur() {
        this.pausePlayback();
    },

    async startPlayback() {
        if (!this.startedInitialisingPlayer) {
            await this.initPlayer();
            return;
        }

        if (!this.playerIsVisibleOnScreen || !this.playerIsReady || !this.playerInstance) {
            return;
        }

        this.playerInstance.muted = true;
        this.playerInstance.play();
    },

    pausePlayback() {
        if (this.playerInstance) {
            this.playerInstance.pause();
        }
    },

    async initPlayer() {
        if (this.startedInitialisingPlayer) return;
        this.startedInitialisingPlayer = true;

        // load the hls library
        const { default: Hls } = await import("hls.js");

        // prepare a player instance
        this.playerInstance = document.createElement("video");
        if (this.contain) {
            this.playerInstance.setAttribute("class", "w-full h-full object-contain");
        } else {
            this.playerInstance.setAttribute("class", "w-full h-full object-cover");
        }
        this.playerInstance.setAttribute("loop", true);
        this.playerInstance.setAttribute("muted", true);
        this.playerInstance.setAttribute("defaultMuted", true);
        this.playerInstance.setAttribute("volume", 0);
        this.playerInstance.setAttribute("playsinline", true);
        this.playerInstance.setAttribute("disable-picture-in-picture", true);
        // this.playerInstance.setAttribute("poster", this.thumbnailUrl);

        // init the stream
        if (this.playerInstance.canPlayType("application/vnd.apple.mpegurl")) {
            this.playerInstance.src = this.streamUrl;
        } else if (Hls.isSupported()) {
            const hls = new Hls({
                startLevel: 3, // 720p
            });

            hls.loadSource(this.streamUrl);
            hls.attachMedia(this.playerInstance);

            this.hls = hls;
        }

        this.playerInstance.addEventListener(
            "loadedmetadata",
            () => {
                this.playerIsReady = true;
                this.startPlayback();
            },
            { once: true }
        );

        this.playerInstance.addEventListener('playing', () => {
            this.playerIsLoading = false;
        });

        // this.playerInstance.addEventListener('waiting', () => {
        //     this.playerIsLoading = true;
        // });

        // add the player to the dom
        if (this.$refs.wrapper) {
            this.$refs.wrapper.appendChild(this.playerInstance);

            // catch and disable media hardware keys
            navigator?.mediaSession?.setActionHandler("play", () => {});
            navigator?.mediaSession?.setActionHandler("pause", () => {});
        }
    },

    destroy() {
        if (this.hls) {
            this.hls.destroy();
        }
    },
});
