import Swiper from "swiper";
import { Autoplay, Keyboard } from "swiper/modules";
import anime from "animejs";

export default (numberOfSlides) => ({
    swiper: null,
    activeSlide: 0,
    titleHeight: "auto",
    isReady: false,
    rendertest: false,

    get isMobile() {
        return !this.$store.breakpoint.md;
    },

    async init() {
        this.onResize();

        if (numberOfSlides > 1) {
            this.initSwiper();
        }

        this.$watch("activeSlide", (n, o) => {
            this.animateTitleSlide(n, o);
        });

        await this.$nextTick();
        this.isReady = true;
        this.animateHeight(0);
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    initSwiper() {
        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Autoplay, Keyboard],
            slidesOffsetBefore: 10,
            slidesPerView: "auto",
            initialSlide: this.activeSlide,

            breakpoints: {
                390: {
                    slidesOffsetBefore: 30,
                },
                768: {
                    slidesOffsetBefore: 0,
                    autoHeight: true,
                },
            },

            speed: 500,
            autoplay: {
                delay: 4000,
            },
            keyboard: {
                enabled: true,
            },
        });

        this.swiper.on("slideChange", () => {
            this.activeSlide = this.swiper.realIndex;
        });
    },

    onResize() {
        this.calculateTitleHeight();
    },

    async calculateTitleHeight() {
        if (this.$refs.swiperWrapper) {
            this.$refs.swiperWrapper.style.height = "auto";
        }

        if (!this.isMobile) {
            this.titleHeight =
                this.$refs["titleSlide" + this.activeSlide].offsetHeight + "px";
        } else {
            this.titleHeight = "auto";
            await this.$nextTick();
            this.titleHeight =
                Math.max(
                    this.$refs.mobileSlide0?.offsetHeight ?? 0,
                    this.$refs.mobileSlide1?.offsetHeight ?? 0,
                    this.$refs.mobileSlide2?.offsetHeight ?? 0,
                    this.$refs.mobileSlide3?.offsetHeight ?? 0,
                ) + "px";
        }
    },

    animateTitleSlide(newSlide, oldSlide) {
        const fromRight = newSlide > oldSlide;
        const offset = 30;
        const duration = this.isMobile ? 10 : 300;
        const stagger = this.isMobile ? 10 : duration / 2;

        anime({
            targets: [
                this.$refs[`titleSlide${oldSlide}title`],
                this.$refs[`titleSlide${oldSlide}text`],
                this.$refs[`titleSlide${oldSlide}link`],
            ],
            translateX: [0, fromRight ? -1 * offset : offset],
            opacity: [1, 0],
            easing: "easeInSine",
            duration,
            delay: anime.stagger(stagger),
            complete: () => {
                if (this.$refs[`titleSlide${oldSlide}`]) {
                    this.$refs[`titleSlide${oldSlide}`].classList.add(
                        "invisible",
                    );
                }
            },
        });

        anime({
            targets: [
                this.$refs[`titleSlide${newSlide}title`],
                this.$refs[`titleSlide${newSlide}text`],
                this.$refs[`titleSlide${newSlide}link`],
            ],
            translateX: [fromRight ? offset : -1 * offset, 0],
            opacity: [0, 1],
            easing: "easeOutSine",
            duration: duration * 1.3,
            delay: anime.stagger(stagger * 1.6, {
                start: duration,
                easing: "easeInOutSine",
            }),
            begin: () => {
                if (this.$refs[`titleSlide${newSlide}`]) {
                    this.$refs[`titleSlide${newSlide}`].classList.remove(
                        "invisible",
                    );
                }
                this.animateHeight(newSlide);
            },
        });
    },

    animateHeight(newSlide) {
        if (this.isMobile) {
            return;
        }

        const outer = {
            height: parseInt(this.titleHeight),
        };

        anime({
            targets: outer,
            height: this.$refs[`titleSlide${newSlide}`].offsetHeight ?? 200,
            duration: 250,
            delay: 400,
            easing: "easeInOutSine",
            update: () => {
                this.titleHeight = outer.height + "px";
            },
        });
    },

    slideTo(slide) {
        this.swiper.slideTo(slide);
        this.swiper.autoplay.stop();
    },
});
